
import {
  Component,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'BlankLayout',
})
export default class BlankLayout extends Vue {

}
